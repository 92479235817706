// libs
import gsap from "gsap"
import ScrollTrigger from "gsap/ScrollTrigger"

import {overlay, webgl} from "modules/constants"

import {indexInParent, isTouch} from "modules/utils"


export class Mission
{
    constructor() {

        this.rafEnPause = false;

        gsap.to('.h1 .l > span', {
            y:'0',
            opacity:1,
            stagger:0.09,
            ease:'power4.out',
            duration:2
        })

        gsap.to('.sticky', {
            yPercent:60,
            ease:'power1.inOut',
            scrollTrigger:{
                trigger:'.m-duo .gauche',
                start:'top 60%',
                end:'bottom 60%',
                scrub: true
            }
        })

        gsap.to('.m-duo', {
            y:'0',
            opacity:1,
            ease:'power4.out',
            duration:2,
            delay:1
        })

        gsap.to('.m-innerP', {
            opacity:1,
            ease:'power4.out',
            duration:1,
            delay:1
        })

        gsap.to('.m-duo .ast', {
            rotation:250,
            ease:'none',
            scrollTrigger:{
                trigger:'.m-duo .ast',
                start:'top bottom',
                end:'bottom top',
                scrub: true
            }
        })

        this.totalBoutons = document.querySelectorAll('.btnSpe').length;
        this.currentBouton = 0;


        if(!isTouch || window.innerWidth > 767){

            this.mouseEnter = this.eventMouseEnter.bind(this);
            document.querySelector('.m-members').addEventListener("mouseenter", this.mouseEnter);
            this.mouseLeave = this.eventMouseLeave.bind(this);
            document.querySelector('.m-members').addEventListener("mouseleave", this.mouseLeave);

            this.mouseMove = this.eventMouseMove.bind(this);
            window.addEventListener("mousemove", this.mouseMove);

            // this.ball = document.getElementById("smile");
            // gsap.set(this.ball, {xPercent: -50, yPercent: -50});
            this.pos = { x: window.innerWidth / 2, y: window.innerHeight / 2 };
            this.mouse = { x: this.pos.x, y: this.pos.y };
            this.speed = 0.2;

            // this.xSet = gsap.quickSetter(this.ball, "x", "px");
            // this.ySet = gsap.quickSetter(this.ball, "y", "px");

            // this.rotation = gsap.quickSetter(this.ball, "rotation", "deg");
            // this.XOld = 0

            this.btn = document.getElementById("toBio");
            gsap.set(this.btn, {xPercent: -50, yPercent: -50});

            this.xBtnSet = gsap.quickSetter(this.btn, "x", "px");
            this.yBtnSet = gsap.quickSetter(this.btn, "y", "px");



            this.addTicker = () => {
                this.monTicker();
                // console.log('ici add ticke')
            }
        
            // ScrollTrigger.create({
            //     trigger:'.m-hero',
            //     start:'top top+=1',
            //     end:'bottom bottom',
            //     onEnter: () => {
            //         gsap.to('#smile', {autoAlpha:1, duration:0.3})
            //         gsap.ticker.add(this.addTicker)
            //     },
            //     onEnterBack: () => {
            //         gsap.to('#smile', {autoAlpha:1, duration:0.3})
            //         gsap.ticker.add(this.addTicker)
            //     },
            //     onLeave: () => {
            //         gsap.to('#smile', {
            //             autoAlpha:0, 
            //             duration:0.3,
            //             rotation:50,
            //             ease:'power3.in'
            //         })
            //         gsap.ticker.remove(this.addTicker)
            //     }
            // })
            gsap.delayedCall(1, () => {
                ScrollTrigger.create({
                    trigger:'.m-members',
                    start:'top bottom',
                    end:'bottom top',
                    immediateRender: false,
                    onEnter: () => {
                        gsap.ticker.add(this.addTicker)
                    },
                    onEnterBack: () => {
                        gsap.ticker.add(this.addTicker)
                    },
                    onLeave: () => {
                        gsap.ticker.remove(this.addTicker)
                    },
                    onLeaveBack: () => {
                        gsap.ticker.remove(this.addTicker)
                    }
                })
            })
            
        }

        //
        // bouton biographie à faire
        //

        // ScrollTrigger.create({
        //     trigger:'.m-champs',
        //     start:'top bottom',
        //     end:'bottom top',
        //     onEnter: () => {
        //         this.defilement();
        //     },
        //     onEnterBack: () => {
        //         this.defilement();
        //     },
        //     onLeave: () => {
        //         window.clearTimeout( this.monInter );
        //     },
        //     onLeaveBack: () => {
        //         window.clearTimeout( this.monInter );
        //     }
        // })
        

        gsap.delayedCall(1, () => {
            ScrollTrigger.create({
                trigger:'.m-members',
                start:'top top',
                end:'bottom bottom',
                immediateRender: false,
                onEnter: () => {
                    this.rafEnPause = true;
                    cancelAnimationFrame(webgl.rafWebgl);
                },
                onEnterBack: () => {
                    this.rafEnPause = true;
                    cancelAnimationFrame(webgl.rafWebgl);
                },
                onLeave: () => {
                    this.rafEnPause = false;
                    webgl.animate();
                },
                onLeaveBack: () => {
                    this.rafEnPause = false;
                    webgl.animate();
                },
            })
        })






        this.currentShape = 0;
        this.totalShapes = 7;

        document.querySelectorAll('.btnSpe').forEach(btn => {
            btn.addEventListener('mouseenter', () => {

                this.currentBouton = indexInParent(btn);

                window.clearTimeout( this.monInter );
                document.querySelector('.btnSpe.current').classList.remove('current')
                btn.classList.add('current')

                //this.animationShape();
            })
            // btn.addEventListener('mouseleave', () => {
            //     this.defilement();
            // })
        })

        // let distMember = 200;
        // if(window.innerWidth < 768){
        //     distMember = 100;
        // }
        gsap.delayedCall(0.4, () => {
            document.querySelectorAll('.m-member img.a').forEach(el => {
                gsap.to(el, {
                    y:'0px',
                    ease:'none',
                    scrollTrigger:{
                        trigger:el.parentNode,
                        start:'top bottom',
                        end:'top top',
                        scrub: true
                    }
                })
                gsap.to(el, {
                    y:'150px',
                    ease:'none',
                    immediateRender: false,
                    scrollTrigger:{
                        trigger:el.parentNode,
                        start:'top top',
                        end:'bottom top',
                        scrub: true
                    }
                })
            })
        })

        overlay.constructor();

    }

    // defilement() {

    //     this.monInter = setInterval( (e) => {
    //         this.currentBouton = (this.currentBouton + 1) % this.totalBoutons;

    //         document.querySelector('.btnSpe.current')?.classList.remove('current')
    //         document.querySelectorAll('.btnSpe')[this.currentBouton].classList.add('current')

    //         this.animationShape();

    //     }, 4000);
    // }

    animationShape() {

        const modele = document.querySelector('.lesImgs image');
        const newDom = modele.cloneNode(true)
        newDom.setAttribute('xlink:href', document.querySelector('.btnSpe.current').getAttribute('data-img'))
        document.querySelector('.lesImgs').append(newDom);
        gsap.fromTo(newDom, {
            opacity:0
        },{
            opacity:1,
            duration:0.6,
            ease:"power4.inOut"
        })

        this.currentShape = (this.currentShape + 1) % this.totalShapes;
        gsap.to('#forme1', {
            morphSVG:"#forme" + (this.currentShape + 1), 
            duration:0.6, 
            ease:'power4.inOut'
        })
    }

    monTicker() {

        // adjust speed for higher refresh monitors
        const dt = 1.0 - Math.pow(1.0 - this.speed, gsap.ticker.deltaRatio()); 

        this.pos.x += (this.mouse.x - this.pos.x) * dt;
        this.pos.y += (this.mouse.y - this.pos.y) * dt;

        // this.xSet(this.pos.x);
        // this.ySet(this.pos.y);

        this.xBtnSet(this.pos.x);
        this.yBtnSet(this.pos.y);

        // this.rotation((this.pos.x - this.XOld)/2)

        this.XOld = this.pos.x;
    }

    eventMouseMove(e) {
        this.mouse.x = e.x;
        this.mouse.y = e.y;
    }

    eventMouseEnter(e) {
        gsap.to('#toBio', {
            autoAlpha:1,
            ease:"power2.inOut",
            duration:0.3
        })
    }
    eventMouseLeave(e) {
        gsap.to('#toBio', {
            autoAlpha:0,
            ease:"power2.inOut",
            duration:0.3
        })
    }

    kill() {

        window.removeEventListener("mousemove", this.mouseMove);
        document.querySelector('.m-members').removeEventListener("mouseenter", this.mouseEnter);
        document.querySelector('.m-members').removeEventListener("mouseleave", this.mouseLeave);

        window.clearInterval( this.monInter );
        gsap.ticker.remove(this.addTicker);
    }
}