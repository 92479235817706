// libs
import gsap from "gsap"
import ScrollTrigger from "gsap/ScrollTrigger"

// import {overlay} from "modules/constants"

import {indexInParent, shuffle} from "modules/utils"


export class Demarche
{
    constructor() {

        this.animEnCours = false;

        gsap.to('.h1 .l > span', {
            y:'0',
            opacity:1,
            stagger:0.09,
            ease:'power4.out',
            duration:2
        })

        gsap.to('.d-flex, .h-moduleDebut', {
            autoAlpha:1,
            ease:'power4.inOut',
            duration:1,
            delay:0.7
        })

        gsap.delayedCall(0.3,() => {
            document.querySelectorAll('.innerDroite .droite').forEach(el => {
                ScrollTrigger.create({
                    trigger:el,
                    start:'top top+=' + 5,
                    onEnter: () => {
                        this.updateSidebar(el);
                    },
                    onEnterBack: () => {
                        this.updateSidebar(el);
                    }
                })
            })
        })

        document.querySelectorAll('.innerGauche .btn').forEach(btn => {
            btn.addEventListener('click', () => {

                const index = indexInParent(btn.parentNode);

                this.animEnCours = true;
                document.querySelector('.innerGauche.current').classList.remove('current')
                document.querySelectorAll('.innerGauche')[index].classList.add('current')

                const progress = {}
                progress.scroll = window.pageYOffset;
                gsap.to(progress, {
                    scroll: document.querySelectorAll('.innerDroite .droite')[index].getBoundingClientRect().top + window.pageYOffset - 4,
                    duration:1,
                    ease:'power3.inOut',
                    onUpdate:() => {
                        window.scrollTo(0, progress.scroll)
                    },
                    onComplete:() => {
                        this.animEnCours = false;
                    }
                })
            })
        })
        
    }

    updateSidebar(elmt) {

        //if(!this.animEnCours){
        const index = indexInParent(elmt);

        let i = 0;
        document.querySelectorAll('.innerGauche .btn').forEach(btn => {
            
            if(i != index){
                btn.querySelector('svg').classList.remove('fHaut', 'fBas')
                if(i < index){
                    btn.querySelector('svg').classList.add('fHaut')
                }else{
                    btn.querySelector('svg').classList.add('fBas')
                }
            }

            i++; 
        })

        if(!this.animEnCours){
            document.querySelectorAll('.innerGauche').forEach(el => {
                el.classList.remove('current')
            })
            document.querySelectorAll('.innerGauche')[index].classList.add('current')
        }
        //}
    }
}