// libs
import gsap from "gsap"
import ScrollTrigger from "gsap/ScrollTrigger"

// import { MorphSVGPlugin } from "gsap/MorphSVGPlugin";
import { SingleCarousel } from "SingleCarousel";


export class Single
{
    constructor() {
        this.animEnCours = false;
        document.querySelectorAll('.carouImg').forEach(carousel => {
            
            new SingleCarousel(carousel)

            // let currentShape = 0;
            // let totalShapes = 7;
            // let indexCarou = carousel.getAttribute('data-index');

            // let currentImg = carousel.querySelectorAll('.lesImgs image').length-1;
            // let totalImg = carousel.querySelectorAll('.lesImgs image').length;

            // carousel.querySelector('.prev').addEventListener('click', () => {

            //     if(!this.animEnCours){
            //         this.animEnCours = true;

            //         currentImg--;
            //         if(currentImg < 0) currentImg = totalImg-1;

            //         const nextEl = carousel.querySelectorAll('.lesImgs image')[currentImg];

            //         let newDom = nextEl.cloneNode(true)
            //         carousel.querySelector('.lesImgs').append(newDom);
            //         gsap.fromTo(newDom, {
            //             opacity:0
            //         },{
            //             opacity:1,
            //             duration:0.6,
            //             ease:"power4.inOut"
            //         })

            //         currentShape = (currentShape + 1) % totalShapes;
            //         gsap.to(carousel.querySelector('#forme-' + indexCarou + '-1'), {
            //             morphSVG:"#forme-" + indexCarou + "-" + (currentShape + 1), 
            //             duration:0.6, 
            //             ease:'power4.inOut', 
            //             onComplete:() => {
            //                 this.animEnCours = false;
            //                 gsap.set(carousel.querySelector('.lesImgs image'), {opacity:0})
            //                 gsap.set(newDom, {opacity:1})
            //             }
            //         })
            //     }
            // });

            // carousel.querySelector('.next').addEventListener('click', () => {

            //     if(!this.animEnCours){
            //         this.animEnCours = true;

            //         currentImg = (currentImg + 1) % totalImg;

            //         const nextEl = carousel.querySelectorAll('.lesImgs image')[currentImg];

            //         let newDom = nextEl.cloneNode(true)
            //         carousel.querySelector('.lesImgs').append(newDom);
            //         gsap.fromTo(newDom, {
            //             opacity:0
            //         },{
            //             opacity:1,
            //             duration:0.6,
            //             ease:"power4.inOut"
            //         })

            //         currentShape = (currentShape + 1) % totalShapes;
            //         gsap.to(carousel.querySelector('#forme-' + indexCarou + '-1'), {
            //             morphSVG:"#forme-" + indexCarou + "-" + (currentShape + 1), 
            //             duration:0.6, 
            //             ease:'power4.inOut', 
            //             onComplete:() => {
            //                 this.animEnCours = false;
            //                 gsap.set(carousel.querySelector('.lesImgs image'), {opacity:0})
            //                 gsap.set(newDom, {opacity:1})
            //             }
            //         })
            //     }
            // })
        })


        document.querySelectorAll('.lesQuotes').forEach(quotes => {

            let currentQuote = 0;
            let totalQuote = quotes.querySelectorAll('.uneQuote').length;


            quotes.parentNode.querySelector('.prev').addEventListener('click', () => {
                if(!this.animEnCours){
                    this.animEnCours = true;

                    gsap.to(quotes.querySelectorAll('.uneQuote')[currentQuote], {
                        opacity:0,
                        duration:0.6,
                        ease:"power4.inOut"
                    })

                    currentQuote--;
                    if(currentQuote < 0) currentQuote = totalQuote-1;

                    const nextEl = quotes.querySelectorAll('.uneQuote')[currentQuote];
                    
                    gsap.to(nextEl, {
                        opacity:1,
                        duration:0.6,
                        delay:0.6,
                        ease:"power4.inOut",
                        onComplete: () => {
                            this.animEnCours = false;
                        }
                    })
                }
            });

            quotes.parentNode.querySelector('.next').addEventListener('click', () => {
                if(!this.animEnCours){
                    this.animEnCours = true;

                    gsap.to(quotes.querySelectorAll('.uneQuote')[currentQuote], {
                        opacity:0,
                        duration:0.6,
                        ease:"power4.inOut"
                    })

                    currentQuote = (currentQuote + 1) % totalQuote;

                    const nextEl = quotes.querySelectorAll('.uneQuote')[currentQuote];
                    
                    gsap.to(nextEl, {
                        opacity:1,
                        duration:0.6,
                        delay:0.6,
                        ease:"power4.inOut",
                        onComplete: () => {
                            this.animEnCours = false;
                        }
                    })
                }
            })
        })

        let dist = 320;
        if(window.innerWidth < 768){dist = 245}

        if(document.querySelector('.tempToSingle.actif')){
            document.querySelector('.s-boutons').classList.add('on')
            document.querySelector('.h-module-first').classList.add('on')
            const imgFond = document.querySelector('.tempToSingle > img').getAttribute('src');
            document.querySelector('.fondSingle img').setAttribute('src', imgFond);
            gsap.delayedCall(0.1, () => {
                document.querySelector('.tempToSingle').classList.remove('actif')
            })
        

            gsap.to('.s-boutons', {
                delay:0.1,
                height:window.innerHeight - dist + 'px',
                duration:1,
                ease:'power4.inOut'
            })

            gsap.to('.s-boutons .btn', {
                delay:0.3,
                y:'0%',
                duration:1,
                stagger:0.15,
                ease:'power4.out'
            })
        }else {
            let ajoutDelay = 0;

            if(document.querySelector('.tempToSingle.actif2')){
                gsap.delayedCall(0.1, () => {
                    document.querySelector('.tempToSingle').classList.remove('actif2')
                })
                ajoutDelay = 0.1;
            }
            
            // intro normal
            gsap.set('.s-boutons', {height:window.innerHeight - dist + 'px'})
            gsap.to('.h-module-first', {
                y:'0px',
                duration:1,
                delay:ajoutDelay,
                ease:'power4.out'
            })

            gsap.to('.s-boutons .btn', {
                delay:(0.8 + ajoutDelay),
                y:'0%',
                duration:1,
                stagger:0.15,
                ease:'power4.out',
                onStart:() => {
                    document.querySelector('.s-boutons').classList.add('on')
                }
            })

        }

        gsap.delayedCall(1, () => {
            gsap.to('.blurNoise', {
                opacity: 1,
                ease: 'none',
                // duration:0.5,
                scrollTrigger: {
                    trigger:'.fondSingle',
                    start:'top top',
                    //toggleActions: 'play pause pause reverse'
                    end:'+=500px',
                    scrub:true
                }
            })
        })
        

        // document.documentElement.style.setProperty('--newsletterHeight', document.querySelector('.newsletter').clientHeight +'px')
    }
}