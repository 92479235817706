// libs
import gsap from "gsap"

export class Home
{
    constructor() {

        gsap.to('.toK2', {
            autoAlpha:0,
            duration:0.2
        })

        // ARRIVEE SUR ACTIVE
        if(document.querySelector('html').classList.contains('toActivePro')){
            
            document.querySelector('html').classList.remove('toActivePro')
            // window.scrollTo(0, document.querySelector('.premiersBlocs').getBoundingClientRect().top - 5 - window.innerHeight)






            window.scrollTo(0, document.querySelector('.h-module').getBoundingClientRect().top - 5 - window.innerHeight)






            gsap.set('.bloc1, .pastPro, .pastPro .bloc', {y:'0px'})
            gsap.set('.textsIntro .col2', {
                opacity:1
            })

            gsap.to('.premiersBlocs', {
                y:0,
                ease:'power4.out',
                duration:1
            })
            gsap.to('.premiersBlocs .bloc', {
                y:'0px',
                ease:'power4.out',
                duration:1,
                stagger:0.4
            })

        // ARRIVEE SUR PAST
        }else if(document.querySelector('html').classList.contains('toPastPro')){
            
            document.querySelector('html').classList.remove('toPastPro')
            window.scrollTo(0, document.querySelector('.pastPro').getBoundingClientRect().top - 5 - window.innerHeight)

            gsap.set('.bloc1, .premiersBlocs, .premiersBlocs .bloc', {y:'0px'})
            gsap.set('.textsIntro .col2', {
                opacity:1
            })

            gsap.to('.pastPro', {
                y:0,
                ease:'power4.out',
                duration:1
            })
            gsap.to('.pastPro .bloc', {
                y:'0px',
                ease:'power4.out',
                duration:1,
                stagger:0.4
            })

        // ARRIVEE NORMALE
        }else{

            // modifié tant que pas de projets en cours
            if(document.querySelector('.pastPro .premiersBlocs')){
                gsap.set('.premiersBlocs, .pastPro', {y:0})
            }else{
                gsap.set('.premiersBlocs, .pastPro, .pastPro .bloc', {y:0})
            }

            gsap.set('.premiersBlocs, .pastPro', {y:0})
            gsap.to('.bloc1', {
                y:'0px',
                ease:'power4.out',
                duration:2
            })

            if(window.innerWidth > 767){
                gsap.to('.premiersBlocs .bloc', {
                    y:'0px',
                    ease:'power4.out',
                    duration:1,
                    stagger:0.2,
                    delay:1
                })
            }else{
                gsap.fromTo('.premiersBlocs .bloc', {
                    y:'300px'
                },{
                    y:'0px',
                    ease:'power4.out',
                    duration:1,
                    stagger:0.2,
                    delay:1
                })
            }
            gsap.to('.textsIntro .col2', {
                opacity:1,
                stagger:0.2,
                delay:1
            })
        }
    }
}