// libs
import gsap from "gsap"

export class T404
{
    constructor() {

        gsap.to('.toK2', {
            autoAlpha:0,
            duration:0.2
        })

        this.oldPosX = 0

        this.xTo = gsap.quickTo(".bloc", "x", {duration: 1, ease: "power4"})
        this.yTo = gsap.quickTo(".bloc", "y", {duration: 1, ease: "power4"})
        this.rotTo = gsap.quickTo(".bloc", "rotation", {duration: 1, ease: "power4"})

        
        gsap.to('.bloc', {
            y:'0px',
            ease:'power4.out',
            duration:2,
            onComplete:() => {
                this.mouseE = this.mouseMove.bind(this);
                window.addEventListener('mousemove', this.mouseE)
            }
        })

        this.hoverE = this.hoverEnter.bind(this);
        document.querySelector('.inner-nav').addEventListener('mouseenter', this.hoverE)
        this.hoverL = this.hoverLeave.bind(this);
        document.querySelector('.inner-nav').addEventListener('mouseleave', this.hoverL)

        gsap.to('.bloc span', {
            y:'0px',
            ease:'power4.out',
            duration:2,
            onComplete:() => {

            }
        }) 
    }

    hoverEnter(e) {
        gsap.to('.bloc', {
            opacity:0,
            duration:0.2
        })
    }
    hoverLeave(e) {
        gsap.to('.bloc', {
            opacity:1,
            duration:0.2
        })
    }

    mouseMove(e) {
        this.xTo(e.clientX - window.innerWidth/2)
        this.yTo(e.clientY - window.innerHeight/2)
        this.rotTo((e.clientX - this.oldPosX) / 2)
        this.oldPosX = e.clientX

        window.clearTimeout( this.isMoving );
        this.isMoving = setTimeout( () => {
            this.rotTo(0)
        }, 66);

    }

    kill() {
        window.removeEventListener('mousemove', this.mouseE)
        document.querySelector('.inner-nav').removeEventListener('mouseenter', this.hoverE)
        document.querySelector('.inner-nav').removeEventListener('mouseleave', this.hoverL)

        gsap.to('.bloc', {
            opacity:0,
            duration:0.2
        })
    }
}