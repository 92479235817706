// libs
import gsap from "gsap"
// import ScrollTrigger from "gsap/ScrollTrigger"

// import {overlay} from "modules/constants"

// import {indexInParent} from "modules/utils"


export class FAQ
{
    constructor() {

        gsap.to('h1 .l > span', {
            y:'0',
            opacity:1,
            stagger:0.09,
            ease:'power4.out',
            duration:2
        })

        gsap.to('.f-module .gauche, .f-module .droite', {
            opacity:1, // avant autoAlpha
            y:'0px',
            stagger:0.09,
            ease:'power4.out',
            duration:2,
            delay:0.5
        })

        let hauteur = 75;
        if(window.innerWidth < 768){
            hauteur = 102;
        }

        
        // ouvrir première question
        const firstFAQ = document.querySelectorAll('.f-module .droite')[0]
        firstFAQ.classList.toggle('actif')
        gsap.set(firstFAQ, {
            height: (hauteur + firstFAQ.querySelector('.reponse').clientHeight) + 'px',
        })
        // fin ouvrir première question
        

        document.querySelectorAll('.f-module .droite').forEach(quest => {
            quest.addEventListener('click', () => {
                // console.log(quest.parentNode.querySelector('.reponse').clientHeight)

                quest.classList.toggle('actif');
                if(quest.classList.contains('actif')){
                    gsap.to(quest, {
                        height: (hauteur + quest.querySelector('.reponse').clientHeight) + 'px',
                        ease:'power4.inOut',
                        duration:0.6
                    })
                }else{
                    gsap.to(quest, {
                        height: hauteur + 'px',
                        ease:'power4.inOut',
                        duration:0.6
                    })
                }

                document.querySelectorAll('.f-module .droite').forEach(quest2 => {
                    if(quest2 != quest && quest2.classList.contains('actif')){
                        quest2.classList.remove('actif');
                        gsap.to(quest2, {
                            height: hauteur + 'px',
                            ease:'power4.inOut',
                            duration:0.6
                        })
                    }
                })
            })
        })

    }
}