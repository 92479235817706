// libs
import gsap from "gsap"
import ScrollTrigger from "gsap/ScrollTrigger"

export class SingleCarousel
{
    constructor(carousel) {
        
        this.currentShape = 0;
        this.totalShapes = 7;
        this.indexCarou = carousel.getAttribute('data-index');

        this.currentImg = carousel.querySelectorAll('.lesImgs image').length-1;
        this.totalImg = carousel.querySelectorAll('.lesImgs image').length;

        carousel.querySelector('.prev').addEventListener('click', () => {

            if(!this.animEnCours){
                this.animEnCours = true;

                this.currentImg--;
                if(this.currentImg < 0) this.currentImg = this.totalImg-1;

                const nextEl = carousel.querySelectorAll('.lesImgs image')[this.currentImg];

                let newDom = nextEl.cloneNode(true)
                carousel.querySelector('.lesImgs').append(newDom);
                gsap.fromTo(newDom, {
                    opacity:0
                },{
                    opacity:1,
                    duration:0.6,
                    ease:"power4.inOut"
                })

                this.currentShape = (this.currentShape + 1) % this.totalShapes;
                gsap.to(carousel.querySelector('#forme-' + this.indexCarou + '-1'), {
                    morphSVG:"#forme-" + this.indexCarou + "-" + (this.currentShape + 1), 
                    duration:0.6, 
                    ease:'power4.inOut', 
                    onComplete:() => {
                        this.animEnCours = false;
                        gsap.set(carousel.querySelector('.lesImgs image'), {opacity:0})
                        gsap.set(newDom, {opacity:1})
                    }
                })
            }
        });

        carousel.querySelector('.next').addEventListener('click', () => {

            if(!this.animEnCours){
                this.animEnCours = true;

                this.currentImg = (this.currentImg + 1) % this.totalImg;

                const nextEl = carousel.querySelectorAll('.lesImgs image')[this.currentImg];

                let newDom = nextEl.cloneNode(true)
                carousel.querySelector('.lesImgs').append(newDom);
                gsap.fromTo(newDom, {
                    opacity:0
                },{
                    opacity:1,
                    duration:0.6,
                    ease:"power4.inOut"
                })

                this.currentShape = (this.currentShape + 1) % this.totalShapes;
                gsap.to(carousel.querySelector('#forme-' + this.indexCarou + '-1'), {
                    morphSVG:"#forme-" + this.indexCarou + "-" + (this.currentShape + 1), 
                    duration:0.6, 
                    ease:'power4.inOut', 
                    onComplete:() => {
                        this.animEnCours = false;
                        gsap.set(carousel.querySelector('.lesImgs image'), {opacity:0})
                        gsap.set(newDom, {opacity:1})
                    }
                })
            }
        })
    }
}