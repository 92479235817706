// lib
import gsap from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger"

import {isElementInViewport} from "../utils"

// pages
import {Global} from "Global";
import {Home} from "Home";
import {T404} from "T404";
import {Single} from "Single";
import {Mission} from "Mission";
import {Demarche} from "Demarche";
import {FAQ} from "FAQ";
import {webgl, lenis} from "modules/constants"
// import {Projet} from "../projet/Projet"
// import {TransitionWebgl} from "./TransitionWebgl"



export class Transition
{
    constructor(elmt) {
        this.currentStep = 0;
        this.allSteps = 2; // anim sorti + nouveau dom loadé

        this.newClass;
        this.newTitle;
        this.newDOM;
        this.newHeader;
        this.newFooter;
        this.newVoirCriteres;
        this.newOverlay;
        // this.newHeaderLang;
        // this.newNav;

        this.animEnCours = false;
        this.first = true;
    }

    start (url, elmtClicked) {
        this.animEnCours = true;
        this.changementDOM(url);
        this.animationSortie(elmtClicked);
    }

    // STEP
    animationSortie(elmtClicked) {

        if(lenis !== null){
            lenis.stop()
        }

        if(this.mission != undefined){
            if(document.body.classList.contains('page-template-mission') && this.mission.rafEnPause){
                webgl.animate();
            }
        }


        //
        if(document.querySelector('.inner-nav .droite').classList.contains('actif')){

            gsap.to('.toMenuMob .a', {
                duration:0.4,
                opacity:0
            })
            gsap.to('.toMenuMob .r', {
                duration:0.4,
                opacity:1,
                delay:0.6
            })

            gsap.to('.toMenuMob', {
                width: document.body.getAttribute('data-width-btn') + 'px',
                ease:'power4.inOut',
                duration:1,
                onComplete:() => {
                    document.querySelector('.inner-nav .droite').classList.remove('actif')
                    //if(!document.querySelector('.home')){
                        document.querySelector('.navMob .toK').style.display = "flex";
                        gsap.to('.navMob .toK', {
                            autoAlpha:1,
                            duration:0.2
                        })
                    //}
                    document.querySelector('.toMenuMob').classList.remove('closeMob')
                }
            })

            // gsap.to('.innerUl', {
            //     y:'-100%',
            //     ease:'power4.inOut',
            //     duration:1
            // })
            // gsap.to('.nav', {
            //     y:'100%',
            //     ease:'power4.inOut',
            //     duration:1
            // })

            gsap.to('.inner-nav', {
                "clip-path": "polygon(0% 0%, 100% 0%, 100% 0%, 0% 0%)",
                ease:'power4.inOut',
                duration:1
            })
            gsap.to('.poly', {
                opacity:0,
                duration:0.2
            })
        }

        if(this.global != undefined){
            this.global.killGlobal();
        }

        if(this.first){
            this.killAllSTrigger();
            this.incrementStep();
        }else if(document.body.classList.contains('toSingleTransition')){

            // NEWWWWW
            // NEWWWWW
            // NEWWWWW
            // NEWWWWW
            // COMMENTE LE 02/10/2023 ?
            // elmtClicked.closest('#main').classList.add('hidden');
            // const gauche = elmtClicked.closest('.h-module').querySelector('.gauche')
            // const distTop = elmtClicked.closest('.droite').getBoundingClientRect().top;
            // if(distTop < 0){
            //     gsap.set(gauche, {y: -elmtClicked.closest('.droite').getBoundingClientRect().top + 5})
            //     gauche.classList.remove('elDom')
            // }
            // FIN NEWWWWW
            // FIN NEWWWWW
            // FIN NEWWWWW
            // FIN NEWWWWW

            //elmtClicked.closest('.wrap.flex').style.margin = "0 0 0 5px";
            gsap.set(elmtClicked.closest('.wrap.flex'), {width:(window.innerWidth + elmtClicked.closest('.droite').getBoundingClientRect().left ) + 'px'})
            
            gsap.to(elmtClicked.closest('.wrap.flex'), {
                x:-1 * elmtClicked.closest('.droite').getBoundingClientRect().left + 5, // avt sur .h-module
                // width: (window.innerWidth + elmtClicked.closest('.droite').getBoundingClientRect().left - 10) + 'px',
                ease:'power4.inOut',
                duration:1
            })
            gsap.to(elmtClicked.closest('.droite'), {
                width: (window.innerWidth - 10 - Math.max(window.innerWidth - document.documentElement.clientWidth, 0)) + 'px',
                ease:'power4.inOut',
                duration:1
            })

            let dist = window.innerHeight - elmtClicked.closest('.droite').getBoundingClientRect().top - 270;
            if(window.innerWidth < 768){
                dist = window.innerHeight - elmtClicked.closest('.droite').getBoundingClientRect().top - 140;
            }
            gsap.to(elmtClicked.closest('.droite'), {
                y: dist + 'px', // hauteur mots
                ease:'power4.inOut',
                duration:1,
                delay:0.3
            })

            document.querySelector('.fondAnim img').setAttribute('src', elmtClicked.closest('.droite').querySelector('.innerMask .media').getAttribute('src'));
            document.querySelector('.tempToSingle > img').setAttribute('src', elmtClicked.closest('.droite').querySelector('.innerMask .media').getAttribute('src'));
            document.querySelector('.tempToSingle .lettres').innerHTML = elmtClicked.closest('.droite').querySelector('.lettres').innerHTML;
            document.querySelector('.tempToSingle p.h2').innerHTML = elmtClicked.closest('.droite').querySelector('.title').innerHTML;

            gsap.to('.fondAnim', {
                y:'0%',
                ease:'power4.inOut',
                duration:1,
                delay:0.3
            })
            gsap.to('.fondAnim img', {
                y:'0%',
                ease:'power4.inOut',
                duration:1,
                delay:0.3,
                onComplete:() => {
                    document.querySelector('.tempToSingle').classList.add('actif');
                    this.killAllSTrigger();
                    this.incrementStep();
                }
            })

            // enlever dom alentours
            document.querySelectorAll('.elDom').forEach(el => {
                //console.log(isElementInViewport(el));
                if(isElementInViewport(el) && el != elmtClicked.closest('.elDom')){

                    let dist;
                    if(el.getBoundingClientRect().bottom > 0 && el.getBoundingClientRect().top < 0){
                        dist = el.getBoundingClientRect().bottom
                    }else if(el.getBoundingClientRect().bottom > window.innerHeight && el.getBoundingClientRect().top < window.innerHeight){
                        dist = el.getBoundingClientRect().top - window.innerHeight
                    }

                    //console.log('elsDom', el, dist, el.getBoundingClientRect().bottom)

                    gsap.to(el, {
                        y: -1 * dist + 'px',
                        ease:'power4.inOut',
                        duration:1
                    })
                }
            })

            
        }else{
            
            document.querySelectorAll('.elDom').forEach(el => {
                
                if(
                    (isElementInViewport(el) && el != elmtClicked.closest('.elDom')) ||
                    (isElementInViewport(el) && el == elmtClicked.closest('footer')) 
                ){
                    let dist;

                    if(el.getBoundingClientRect().bottom > 0 && el.getBoundingClientRect().top < 0){
                        dist = el.getBoundingClientRect().bottom
                    }else if(el.getBoundingClientRect().bottom > window.innerHeight && el.getBoundingClientRect().top < window.innerHeight){
                        dist = el.getBoundingClientRect().top - window.innerHeight
                    }else{
                        const centrage = (el.getBoundingClientRect().bottom - el.getBoundingClientRect().top) / 2 + el.getBoundingClientRect().top;
                        if(centrage <= window.innerHeight/2){
                            // console.log('plus pres du haut')
                            dist = el.clientHeight + el.getBoundingClientRect().top
                        }else{
                            // console.log('plus pres du bas')
                            dist = -1 * (el.clientHeight + window.innerHeight - (el.clientHeight + el.getBoundingClientRect().top))
                        }
                    }

                    gsap.to(el, {
                        y: -1 * dist + 'px',
                        ease:'power4.inOut',
                        duration:1,
                        //delay:i
                    })
                    //i += 0.1;
                }
            })

            let delayTime = 1;

            if(document.body.classList.contains('single-projets')){
                delayTime = 1.4;
                if(!document.body.classList.contains('toSingle')){
                    
                    gsap.fromTo('.fondSingle', {
                        "clip-path": "polygon(0% 0%, 100% 0%, 100% 100%, 0% 100%)"
                    },{
                        "clip-path": "polygon(0% 0%, 100% 0%, 100% 0%, 0% 0%)",
                        ease:'power4.inOut',
                        duration:1,
                        delay:0.4
                    })
                    gsap.to('.fondSingle > div', {
                        y:'-10%',
                        ease:'power4.inOut',
                        duration:1,
                        delay:0.4
                    })

                    gsap.fromTo('canvas', {
                        y:'10%'
                    },{
                        y:'0%',
                        ease:'power4.inOut',
                        duration:1,
                        delay:0.4
                    })
                }else {
                    // cas dune single a une autre (par changement de langue surement)
                    
                    document.querySelector('.tempToSingle > img').setAttribute('src', document.querySelector('.fondSingle img').getAttribute('src'));
                    gsap.to('.blurNoise', {
                        opacity:0,
                        ease:'power4.inOut',
                        duration:1,
                        delay:0.4,
                        onComplete:() => {
                            document.querySelector('.tempToSingle').classList.add('actif2');
                        }
                    })

                    // ATTENTION
                    // manque cas ou precedent d'une single a une autre mais pas la même
                    // ATTENTION VOIR EN DESSOUS

                    // A MODIFIER
                    // document.querySelector('.fondAnim img').setAttribute('src', elmtClicked.closest('.bas').querySelector('.innerMask').getAttribute('src'));
                    // document.querySelector('.tempToSingle > img').setAttribute('src', elmtClicked.closest('.bas').querySelector('.innerMask').getAttribute('src'));
                    // document.querySelector('.tempToSingle .lettres').innerHTML = elmtClicked.closest('.droite').querySelector('.lettres').innerHTML;
                    // document.querySelector('.tempToSingle h3.h2').innerHTML = elmtClicked.closest('.droite').querySelector('h3.h2').innerHTML;

                    // gsap.to('.fondAnim', {
                    //     y:'0%',
                    //     ease:'power4.inOut',
                    //     duration:1,
                    //     delay:0.3
                    // })
                    // gsap.to('.fondAnim img', {
                    //     y:'0%',
                    //     ease:'power4.inOut',
                    //     duration:1,
                    //     delay:0.3,
                    //     onComplete:() => {
                    //         document.querySelector('.tempToSingle').classList.add('actif');
                    //     }
                    // })
                    // FIN A MODIFIER

                }
            }else if(document.body.classList.contains('home')){
                gsap.to('.textsIntro', {
                    opacity:0,
                    duration:0.5
                })
            }

            gsap.delayedCall(delayTime, () => {
                this.killAllSTrigger();
                this.incrementStep();
            })

            
        }

        if(!webgl.actif){
            document.querySelector('canvas').classList.remove('off')
            webgl.init(); // ancien mouseevent
            webgl.animate();
            webgl.actif = true;
        }

        if(this.mission != undefined && document.body.classList.contains('page-template-mission')){
            this.mission.kill();
        }

        if(this.t404 != undefined && document.body.classList.contains('error404')){
            this.t404.kill();
        }
    }

    killAllSTrigger() {
        let triggers = ScrollTrigger.getAll();
        triggers.forEach( trigger => {
            trigger.kill();
        });
    }

    // STEP
    changementDOM(url) {
        this.ajaxPromise(url).then((data) => {
            let parser = new DOMParser();
            let doc = parser.parseFromString(data, "text/html");
            let liste_class = doc.body.getAttribute('class');
            
            // maj titre de la page
            this.newTitle = doc.querySelector('title').innerHTML;
            // this.newNav = doc.querySelector('header').innerHTML;
            this.newClass = doc.body.getAttribute('class');

            this.newHeader = doc.querySelector('.inner-nav').innerHTML;

            this.newVoirCriteres = doc.querySelector('.voirCriteres').innerHTML;

            this.newFooter = doc.querySelector('footer').innerHTML;
            this.newDOM = doc.getElementById('main').innerHTML;
            // this.newHeaderLang = doc.querySelector('.poly').innerHTML;

            this.newOverlay = doc.querySelector('.allOverlays').innerHTML;

            this.incrementStep();  
        });
    }

    // ALL STEPS COMPLETE
    animationEntree(titre, classes, DOM) {
        //reset
        window.scrollTo(0,0);
        this.currentStep = 0;

        document.getElementById('main').classList.remove('hidden');

        document.title = this.newTitle;
        
        //if(document.querySelector('.changeLang')){
        document.querySelector('.inner-nav').innerHTML = this.newHeader;
        //}

        document.body.setAttribute('class', this.newClass);
        document.querySelector('footer').innerHTML = this.newFooter;
        document.getElementById('main').innerHTML = this.newDOM;

        document.querySelector('.voirCriteres').innerHTML = this.newVoirCriteres;

        document.querySelector('.allOverlays').innerHTML = this.newOverlay;



        

        // document.querySelector('.poly').innerHTML = this.newHeaderLang;

        gsap.set('footer', {clearProps:'all'})

        this.animEnCours = false;

        // gestion webgl
        if(document.body.classList.contains('single')){ // si page avec no webgl
            document.querySelector('canvas').classList.add('off')
            webgl.killWebgl();
            webgl.actif = false;
        }else {
            webgl.updateBlocs();
        }

        
        // first animation ?
        // fin first animation

        // var vid = document.getElementById("video");
        // vid.onloadeddata = function() {
        //     alert("Browser has loaded the current frame");
        // };

        if(this.first){
            
            this.disparitionIntro();

            // test pour enlever saut
            // document.querySelector('.tempToSingle').classList.remove('first')

            if(document.body.classList.contains('single-projets')){
                gsap.to('.fondSingle', {opacity:1, delay:1, onComplete:() => {
                    this.lancements();
                    gsap.set('canvas', {opacity:1})
                }})
                gsap.delayedCall(3, () => {
                    if(lenis !== null){
                        lenis.start()
                    }
                })
            }else{
                gsap.delayedCall(3, () => {
                    this.lancements()

                    if(lenis !== null){
                        lenis.start()
                    }
                })
            }

            gsap.to('.inner-nav', {
                y:'0px', ease:'power4.out', duration:1, delay:2.5
            })

            this.first = false;
        }else{

            if(lenis !== null){
                lenis.scrollTo(0, { immediate: true })
                gsap.delayedCall(1.6, () => {
                    lenis.start()
                })
            }

            document.querySelector('.fondSingle')?.classList.add('on')
            this.lancements();
        }
    }

    lancements() {
        // animations globals
        this.global = new Global();

        if(document.querySelector('.home')){
            this.home = new Home();
        }else if(document.querySelector('.single')){
            this.single = new Single();
        }else if(document.querySelector('.page-template-mission')){
            this.mission = new Mission();
        }else if(document.querySelector('.page-template-demarche')){
            this.demarche = new Demarche();
        }else if(document.querySelector('.page-template-faq')){
            this.faq = new FAQ();
        }else if(document.querySelector('.error404')){
            this.t404 = new T404();
        }else if(document.querySelector('.awwwards')){
            // alert()
            let disttotal
            const easing = (x) => x < 0.5 ? 4 * x * x * x : 1 - Math.pow(-2 * x + 2, 3) / 2;

            gsap.delayedCall(2, () => {
                const distImg = document.querySelector('.content1').getBoundingClientRect().top
                const dist = (window.innerHeight - document.querySelector('.content1').clientHeight) / 2
                lenis.scrollTo(distImg - dist, {duration:2, easing})
                disttotal = distImg - dist
            })


            gsap.delayedCall(4.4, () => {
                const distImg = document.querySelector('.content2').getBoundingClientRect().top
                const dist = (window.innerHeight - document.querySelector('.content2').clientHeight) / 2
                lenis.scrollTo(distImg - dist + disttotal, {duration:2, easing})
                disttotal += distImg - dist
            })

            gsap.delayedCall(6.8, () => {
                const distImg = document.querySelector('.content3').getBoundingClientRect().top
                const dist = (window.innerHeight - document.querySelector('.content3').clientHeight) / 2
                lenis.scrollTo(distImg - dist + disttotal, {duration:2, easing})
                disttotal += distImg - dist
            })
            gsap.delayedCall(9.2, () => {
                lenis.scrollTo(document.body.clientHeight + window.innerHeight, {duration:2, easing})
            })
            
        }

        
    }

    disparitionIntro() {
        gsap.fromTo('#intro .col2', {
            y:'60%',
            opacity:0
        },{
            y:'0%',
            stagger:0.2,
            duration:1.2,
            delay:0.5,
            opacity:1,
            ease:'power2.out'
        })
        gsap.to('#intro .transform, .introRond', {
            opacity:1,
            delay:0.5,
            duration:0.5
        })
        gsap.to('.introRond', {
            rotate:90,
            duration:4,
            ease:'power1.inOut'
        })
        gsap.to('.introRond svg', {
            rotate:360,
            repeat:1,
            duration:2,
            ease:'power2.inOut'
        })
        gsap.delayedCall(2.5, () => {
            gsap.to('#intro', {
                y:'-100%',
                ease:'power4.inOut',
                duration:1.5,
            })
            gsap.to('#introChild', {
                y:'100%',
                ease:'power4.inOut',
                duration:1.5,
            })
            gsap.to('#intro .transform', {
                y:0,
                ease:'power4.inOut',
                duration:1.5,
                onComplete:() => {
                    document.getElementById('intro').classList.add('off')
                }
            }) 

            if(document.querySelector('.textsIntro')){
                gsap.set('.textsIntro .col2', {opacity:1})
                gsap.from('.textsIntro', {
                    y: 0.5 * window.innerHeight - 30 + 'px',
                    ease: 'power4.inOut',
                    duration:1.5
                })
                
            }
        })
            

    }



    //
    //
    // util class
    //
    //
    incrementStep() {
        this.currentStep++;
        if(this.currentStep == this.allSteps) {
            this.animationEntree();
        }
    }

    ajaxPromise (url) {
        return new Promise((resolve) => { //, reject
            const xhr = new XMLHttpRequest();
            xhr.open("GET", url);
            xhr.onload = () => resolve(xhr.responseText);
            xhr.send();
        });
    }
}