import {Webgl} from "Webgl"
import {Overlay} from "Overlay"
import { isTouch } from 'modules/utils'
import Lenis from '@studio-freight/lenis'

export const webgl = new Webgl();

export const overlay = new Overlay();

//smooth
export let lenis = !isTouch() ? new Lenis() : null;
if(lenis){
	lenis.stop()
	function raf(time) {
	 	lenis.raf(time)
	 	requestAnimationFrame(raf)
	}
	requestAnimationFrame(raf)
}
export function redeclare() {
	lenis = !isTouch() ? new Lenis() : null
	// return lenis
}
//fin smooth



// wrapper:document.getElementById('main'),
// content:document.getElementById('main')
// wheelEventsTarget:document.getElementById('main')