// libs
import gsap from "gsap"
import ScrollTrigger from "gsap/ScrollTrigger"

// modules
import {shuffle, indexInParent} from "../utils"


export class Global
{
    constructor() {
        // 
        // global
        //

        if(document.querySelector('.animSplit')){
            let split = [];
            document.querySelectorAll('.animSplit span').forEach(lettre => {
                split.push(lettre)
            })
            const split2 = shuffle(split);
            gsap.to(split2, {
                opacity:1,
                y:'0px',
                stagger:0.1,
                duration:0.3,
                delay:0.1,
                ease:'power4.out'
                
            })
        }


        // if(document.querySelector('.glitch')){
        //     document.querySelectorAll('.glitch').forEach(el => {
        //         ScrollTrigger.create({
        //             trigger:el,
        //             start:'top 90%',
        //             onEnter:() => {
        //                 el.classList.add('on')
        //             }
        //         })
        //     })
        // }


        if(
            !document.body.classList.contains('home')
            && document.querySelector('.toK2').style.visibility == 'hidden'
        ){
            gsap.to('.toK2', {
                autoAlpha:1,
                duration:0.2
            })
        }



        let formes = [1, 2, 3, 4, 5, 6, 7];
        formes = shuffle(formes);
        let i = 0;
        document.querySelectorAll('.innerM').forEach(el => {
            el.querySelector('.innerMask').classList.add('innerMask' + formes[i])
            el.querySelector('.devant').setAttribute('src', el.querySelector('.devant').getAttribute('data-url') + '/img/formes/' + formes[i] + 'b.svg');
            i++;
        })

        this.textSelectionne = false;

        gsap.delayedCall(0.2, () => {
            document.querySelectorAll('.ast1').forEach(el => {
                gsap.to(el, {
                    rotate:-760,
                    ease:'none',
                    scrollTrigger: {
                        trigger: el.closest('.h-module'),
                        start:'top bottom',
                        end:'bottom top',
                        scrub:0.5
                    }
                })
            })

            document.querySelectorAll('.bar').forEach(el => {
                gsap.to([el, el.querySelector('.mask')], {
                    x: '0px',
                    ease:'power4.inOut',
                    duration:2,
                    scrollTrigger:{
                        trigger:el,
                        start:'top 90%'
                    }
                })
                gsap.to(el.querySelector('span'), {
                    opacity:1,
                    ease:'none',
                    duration:1,
                    delay:1,
                    scrollTrigger:{
                        trigger:el,
                        start:'top 90%'
                    }
                })
            })
        })

        if(document.querySelector('.lettres2')){
            document.querySelectorAll('.lettres2').forEach(el => {
                // el.addEventListener('mouseenter', () => {
                //     el.classList.add('hovered')
                // })
                // el.addEventListener('mouseleave', () => {
                //     el.classList.remove('hovered')
                // })
                el.querySelectorAll('span:not(.tab)') && el.querySelectorAll('span:not(.tab)').forEach(lettre => {

                    if(lettre.parentNode.querySelector('.tab')){
                        lettre.addEventListener('mouseenter', (e) => {
                            lettre.parentNode.querySelector('.tab').innerHTML = lettre.parentNode.getAttribute('data-mot-'+indexInParent(lettre))
                            lettre.parentNode.querySelector('.tab').classList.add('actif');
                            lettre.classList.add('hovered')

                            gsap.set(document.querySelector('.hovered').parentNode.querySelector('.tab'), {
                                x:e.clientX - document.querySelector('.hovered').parentNode.getBoundingClientRect().left,
                                y:e.clientY - document.querySelector('.hovered').getBoundingClientRect().top - 40
                            })
                        })
                        lettre.addEventListener('mouseleave', () => {
                            lettre.parentNode.querySelector('.tab').classList.remove('actif');
                            lettre.classList.remove('hovered')
                        })
                    }
                })
            })

            this.mouseE = this.mouseMove.bind(this);
            window.addEventListener('mousemove', this.mouseE)

        }

        document.addEventListener('selectionchange', () => {
            if(
                window.getSelection().toString() != '' 
                && !this.textSelectionne
            ){
                this.textSelectionne = true;
                this.animationSelection();
            }else if(
                window.getSelection().toString() == '' 
                && this.textSelectionne
            ){
                this.textSelectionne = false
                clearInterval(this.setInter)
                document.body.classList.remove('c0','c1','c2','c3','c4','c5','c6','c7','c8','c9');
                document.body.classList.remove('c10','c11','c12','c13','c14','c15','c16','c17','c18','c19');
                document.body.classList.remove('c20','c21','c22','c23','c24','c25','c26','c27','c28','c29');
                document.body.classList.remove('c30','c31','c32','c33','c34','c35','c36','c37','c38','c39');
                document.body.classList.remove('c40','c41','c42','c43','c44','c45','c46','c47','c48','c49');
                document.body.classList.remove('c50','c51','c52','c53','c54','c55','c56','c57','c58','c59');
                document.body.classList.remove('c60','c61','c62','c63','c64');
            }

        });


        // parallax
        gsap.delayedCall(1, () => {
            document.querySelectorAll('.parallaxMedia').forEach(el => {
                const media = el.querySelector('.media');
                gsap.to(media, {
                    y:'0%',
                    ease:'none',
                    scrollTrigger:{
                        trigger:el,
                        start:'top bottom',
                        end:'bottom top',
                        scrub: true
                    }
                })
            }) 
        })
    }

    mouseMove(e) {
        if(document.querySelector('.hovered')){
            gsap.set(document.querySelector('.hovered').parentNode.querySelector('.tab'), {
                x:e.clientX - document.querySelector('.hovered').parentNode.getBoundingClientRect().left,
                y:e.clientY - document.querySelector('.hovered').getBoundingClientRect().top - 40
            })
        }
    }

    animationSelection() {
        //animation texte selectionné
        let couleur = 0;
        this.setInter = setInterval(() => {
            document.body.classList.remove('c'+couleur)
            couleur = (couleur + 1) % 65;
            document.body.classList.add('c'+couleur)
        }, 50 )
    }

    killGlobal() {
        clearInterval(this.setInter)
        window.removeEventListener('mousemove', this.mouseE)
    }
}