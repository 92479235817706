// libs
import gsap from "gsap"
import {isTouch} from "utils"


export class Overlay
{
    constructor() {
    }

    init() {

        document.querySelectorAll('.overlay .gauche').forEach(el => {
            if(!el.classList.contains('deja-declare')){
                el.classList.add('deja-declare');
                el.addEventListener('mouseenter', () => {
                    el.classList.add('oHovered') 
                })
                el.addEventListener('mouseleave', () => {
                    this.deltaX = document.querySelector('.oHovered').clientWidth - 50;
                    el.classList.remove('oHovered')      
                })
            }
        })
        
        if(!isTouch || window.innerWidth > 767){
            this.mouseE = this.mouseMove.bind(this);
            window.addEventListener('mousemove', this.mouseE)

            const el = document.querySelector('.overlay.actif .innerCloseBtn')
            this.xSet = gsap.quickSetter(el, "x", "px");
            
            this.deltaX = this.deplacementX = document.querySelector('.overlay.actif .gauche').clientWidth - 50;
            gsap.set(el, {x:this.deltaX})

            this.addTicker = () => {
                this.monTicker();
            }
            gsap.ticker.add(this.addTicker)

            this.deltaX = window.innerWidth/2 - 52.5;
        }
    }

    monTicker() {

        const dt = 1.0 - Math.pow(0.8, gsap.ticker.deltaRatio()); 
        
        this.deplacementX += (this.deltaX - this.deplacementX) * dt;
        this.xSet(this.deplacementX);
    }

    mouseMove(e) {
        if(document.querySelector('.oHovered')){
            if(e.clientX <= 30){ // 5 + 50/2
                this.deltaX = 5;
            }else if(e.clientX >= document.querySelector('.oHovered').clientWidth - 25){
                this.deltaX = document.querySelector('.oHovered').clientWidth - 50;
            }else{
                this.deltaX = e.clientX - 25;
            }
        }
    }

    killOverlay() {
        window.removeEventListener('mousemove', this.mouseE)
        gsap.ticker.remove(this.addTicker)
    }
}